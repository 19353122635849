import React from "react"
import Reusable from "../../components/articleLayout"
import XTZLogo from "../../images/xtz.png"
import XTZCard from "../../images/TwitterCards/XTZCard.png"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const TezosXTZ = () => (
  <Layout>
    <SEO
      title="Get 1 XTZ for Free"
      description="Learn how to get 1 XTZ for free"
      article={true}
      image={XTZCard}
      pathname="/crypto/tezos-xtz-faucet"
    />
    <Reusable
      CoinbaseRef={false}
      Logo={XTZLogo}
      ImageAlt="XTZ Logo"
      From="faucet.tezos.com"
      Title="Get 1 XTZ for Free"
      Name="Tezos"
      Ticker="XTZ"
      Value="1"
      Address="https://faucet.tezos.com/"
      Step1="Click the “Claim Now” button on this page."
      Step2="You will be redirected to faucet.tezos.com."
      Step3="Copy and past your XTZ address into the tz1 Address field."
      Step4="Enter your phone number into the Phone Number field. (Note: add +1 before your number)"
      Step5="Click the Send Tez button."
      Step6="You will receive a text with a 6 digit verification code."
      Step7="Enter your verification code and click the Send Tez button."
      Step8="You should then see a message saying (Verification Complete Your Request is headed for the queue.)"
      Step9="DO NOT CLOSE YOUR BROWSER OR TAB JUST YET... There will be a series of messages before its complete."
      Step10="The message should then change to (Please wait We are busy processing your request.)"
      Step11="Finally the message should change to (Success Wishing you great inspiration and success.)"
      Step12="Congratulations! You just got 0.01 XTZ for free! (Note: You MUST wait 1 week before submitting again. If you do, you will get banned.)"
      Introduction="In this article I will show you how to get 0.01 XTZ for free every week. Tezos is a cryptocurrency and decentralized computing platform. Tezos uses a proof of stake consensus, allowing holders who stake their tokens to receive Tezos tokens as a reward for creating and verifying blocks. I personally like to use Coinbase to hold my XTZ, if you have at least 1 XTZ in your account, Coinbase will automatically start staking your rewards for you! If you don’t already have a Coinbase account, check out my article on how to get $10 USD free for signing up."
      Introduction2="Tezos Faucet is a real main network faucet that allows users to request 0.01 XTZ every week. Each user can only receive 1 XTZ in total over time. Please don’t be greedy, you must wait no less than 1 week from the last time you requested free XTZ or you will run the risk of being banned. So, make sure you keep track of your time frame. Good luck and enjoy your free crypto!"
    />
  </Layout>
)

export default TezosXTZ
